<template>
<header>
    <h1>later on</h1>
</header>
</template>

<script setup>
</script>

<style scoped>
header {
    height: var(--header-height);
    background-color: var(--b-low);
}

h1 {
    margin: 0 0 0 1.4rem;
    color: var(--f-high);
    font-family: vintage, sans-serif;
    font-size: 1.4rem;
    line-height: 60px;
}
</style>
